import React, { Component } from "react";
import styled from "styled-components";

import Seo from "../SeoHead";
import Background from "./Background";
import Text from "./Text";
import BackButton from "./Back";
import Footer from "./Footer";
import { withPrefix } from "gatsby";
import { P } from "../Typography";
import Helmet from 'react-helmet'

const Section = styled.section`
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
  padding: 2rem;
`;

const Content = styled.div`
  max-width: 30rem;
`;

export default class Thanks2 extends Component {

  render() {
    const { content, renderPlayButton } = this.props

    return (
      <>
        <Seo />
        <Background />
        <Section>
          <Content>
            <Text text={content.text} title={content.title} header={content.header} />

            {renderPlayButton && (
              <div>
                <P style={{ textAlign: 'center' }}>Thanks for joining Sky! You now have access to our private beta.</P>

                <P style={{ marginTop: 12, textAlign: 'center' }}>Click the button below to create your account and start playing!</P>

                <a
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "60px 0",
                    height: 60,
                    cursor: "pointer"
                  }}
                  href={`https://beta.skyweaver.net`}
                  target="_blank"
                  rel="noreferrer"
                >

                  <img style={{ width: 280, position: "absolute" }} src={withPrefix("/images/thanks/play-button-bg.png")} alt="Play Button" />

                  <p
                    style={{
                      fontFamily: "var(--condensedFont)",
                      zIndex: 2,
                      fontWeight: 600,
                      fontSize: 24,
                      color: "#fff"
                    }}
                  >
                    PLAY NOW
                  </p>
                </a>
              </div>
            )}

            <BackButton />
            <Footer />
          </Content>
        </Section>
        <Helmet>
        </Helmet>
      </>
    );
  }
}
