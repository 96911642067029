import React from 'react'
import styled from 'styled-components'

import { Sub } from '../Typography'
import SocialMedia from '../SocialMedia'

const StyledFooter = styled.footer`
  margin-top: 4rem;
  padding-bottom: 2rem;
  width: 100%;
  text-align: center;

  a:hover {
    opacity: 0.5;
  }
  a{transition: 0.3s ease-out opacity;}

  .social-media{
    margin-left: auto; margin-right: auto;
    width: 10rem;
    justify-content: center;
  }

  p {margin-bottom: 1rem;}

`

const Footer = () =>
  <StyledFooter>
    <SocialMedia />
    <a href='mailto:hello@skyweaver.net'><Sub>hello@skyweaver.net</Sub></a>
    <Sub>© {new Date().getFullYear()} Horizon Blockchain Games Inc. All rights reserved. </Sub>
  </StyledFooter>

export default Footer