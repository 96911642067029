import React from 'react'
import styled from 'styled-components'

import { P } from '../Typography'
import Image from '../Image'

const StyledBack = styled.div`
  width: 11rem;
  height: 6.3rem;
  border-radius: 0.5rem;
  background-color: var(--lilac);
  border: 1px solid #725f96;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-out border-color;

  p {font-size: 0.9rem;}
  

  &:hover {
    border-color: #fff;
    
    .text img {
      transform: scale(1, 1) translateX(-0.3rem);
    }
  }

  img {transform: scale(1.1, 1.1)}
`

const StyledText = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);

  img {
    width: 1.3rem;
    margin-right: 0.25rem;
    vertical-align: middle;
    display: inline-block;
    transition: 0.3s ease-out transform;
    transform: scale(1, 1);
  }

  p {
    vertical-align: middle;
    display: inline-block;
    transform: translateY(-0.05rem);
  }
`

const Back = () =>
  <a target="_blank" href='/'>
    <StyledBack>
      <Image src='/images/thanks/icon.png' alt='Return to Skyweaver.net' />
      <StyledText className='text'>
        <img src='/images/thanks/arrow-left.svg' alt='Return Arrow' />
        <P>BACK TO WEBSITE</P>
      </StyledText>
    </StyledBack>
  </a>

export default Back